export default {
  cake: {
    56: "0x2c094F5A7D1146BB93850f629501eB749f6Ed491",
    97: "0x7F511033cFDa8dF0189f9c9BEaD981ae0496901C",
    128: "0x381785593F9BAcE15aF908ac108b5f538155Ff3e"
  },
  masterChef: {
    56: "0x3d34883c175Ba72dDa0108EA1A5faFD6Fa673DB6",
    97: "0xADE2948B4405289e85e697760BB42EED39A5733a",
    128: "0x3d34883c175Ba72dDa0108EA1A5faFD6Fa673DB6"
  },
  rastaMasterChef: {
    97: '0x35b1EF3FF9763F72C11e9f08B471D0b6b5A7515D',
    56: '0xec89Be665c851FfBAe2a8Ded03080F3E64116539',
  },
  masterChefV1: {
    56: "0xEAf239a85b2C24229042a0840771A5620a36D2B3",
    97: "0xADE2948B4405289e85e697760BB42EED39A5733a",
  },
  wbnb: {
    56: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    97: "0x7F511033cFDa8dF0189f9c9BEaD981ae0496901C",
  },
  lottery: {
    56: "",
    97: "",
  },
  lotteryNFT: {
    56: "",
    97: "",
  },
  mulltiCall: {
    56: "0x1ee38d535d541c55c9dae27b12edf090c608e6fb",
    97: "0x35be5ca7917442ed5d2d4cc56ec9988dd158513e",
    128: "0xCeBFbFb4Bd0d28E5a2662193e35E534ca6465f73",
  },
  busd: {
    56: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
    97: "0x7F511033cFDa8dF0189f9c9BEaD981ae0496901C",
  },
  usdt: {
    128: "0xa71EdC38d189767582C38A3145b5873052c3e47a",
  },
  usdc: {
    56: "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
    97: "",
  },
  wnow: {
    128: "0x81c9309D8598fb863bbD337d35DCB6036bcD51Ae",
    97: "",
  },
  eth: {
    56: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
    97: '',
  },
  wht: {
    97: '0xeE14CBfAaD3BADa6fc9886bf2BA11D1c5F373DF2',
    128: '0x5545153CCFcA01fbd7Dd11C0b23ba694D9509A6F',
  },
};
